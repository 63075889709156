import { useEffect, useState } from "react";
import SpecialistImage from "../images/specialist.jpg";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
interface BookSpecialistUuid {
  doctorUuid: any;
}

const BookSpecialist: React.FC<BookSpecialistUuid> = ({ doctorUuid }) => {
  const { id } = useParams();
  const [productData, setProductData] = useState<any>([]);
  const [specialistData, setSpecialistData] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState<any>(new Date());

  const onChange = (newDate: any) => {
    setDate(newDate);
  };

  const handleReviewModal = () => {
    let reviewModal: any = document.getElementById("reviewModal");
    if (reviewModal) {
      reviewModal.click();
    }
  };
  const handleReviewModal2 = () => {
    let reviewModal2: any = document.getElementById("reviewModal2");
    if (reviewModal2) {
      reviewModal2.click();
    }
  };

  const handlBookSlotModal = () => {
    let bookSlotModal: any = document.getElementById("bookSlotModal");
    if (bookSlotModal) {
      bookSlotModal.click();
    }
  };

  useEffect(() => {
    if (doctorUuid.length === 1) {
      const openModal = () => {
        let modal: any = document.getElementById("popUpModal");
        if (modal) {
          modal.click();
        }
      };
      openModal();

      categoryWiseSpecialistProducts();
      getSpecialistDetailsById();
    }
  }, [doctorUuid, id]);

  async function categoryWiseSpecialistProducts() {
    if (doctorUuid.length && id) {
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            doctorId: doctorUuid.length !== 0 ? doctorUuid[0] : 0,
            categoryUuid: id,
            action: "command",
            command: [
              {
                agent: "productBySpecCategory",
                appName: "selfMaximized",
                folder: "specialist",
              },
            ],
          }
        );
        if (result?.data?.statusCode === 200) {
          setProductData(result?.data?.data);
        } else {
          setProductData([]);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  async function getSpecialistDetailsById() {
    try {
      const result = await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 1,
            filters: [
              {
                field: "id",
                operator: "eq",
                value: doctorUuid,
              },
            ],
            sort: [
              {
                field: "id",
                table: "Users",
                dir: "desc",
              },
            ],
          },
        },
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
        action: "command",
        command: [
          {
            agent: "specialistListsData",
            appName: "selfMaximized",
            folder: "specialist",
          },
        ],
      });

      if (result?.data?.statusCode === 200) {
        setSpecialistData(result?.data?.data[0]);
      } else {
        setSpecialistData([]);
      }
    } catch (err) {
      console.error(err);
    }
  }

  console.log(productData);
  console.log(specialistData);

  const showExpData = (type: string) => {
    const language =
      specialistData.length !== 0 && specialistData?.DoctorExperiences[0]
        ? specialistData?.DoctorExperiences[0].language
        : "";
    const areaOfExpertise =
      specialistData.length !== 0 && specialistData?.DoctorExperiences[0]
        ? specialistData?.DoctorExperiences[0].areaofexpertise
        : "";
    const title =
      specialistData.length !== 0 && specialistData?.DoctorExperiences[0]
        ? specialistData?.DoctorExperiences[0].title
        : "";
    let parsedLanguage: any;
    let parsedAreaOfExp: any;

    if (language && areaOfExpertise) {
      parsedLanguage = JSON.parse(language);
      parsedAreaOfExp = JSON.parse(areaOfExpertise);
    }

    if (parsedLanguage && type === "language") {
      return parsedLanguage.join("|");
    }
    if (parsedAreaOfExp && type === "areaofexpertise") {
      return parsedAreaOfExp.map((elem: any) => elem.category).join(" | ");
    }
    return title;
  };
  return (
    <div>
      <input
        className="next-btn"
        type="hidden"
        id="popUpModal"
        data-bs-toggle="modal"
        data-bs-target="#specialistDetails"
      />
      <input
        className="next-btn"
        type="hidden"
        id="bookSlotModal"
        data-bs-toggle="modal"
        data-bs-target="#bookSlotPopUp"
      />
      <input
        className="next-btn"
        type="hidden"
        id="reviewModal"
        data-bs-toggle="modal"
        data-bs-target="#reviewModalOpen"
      />

      <input
        className="next-btn"
        type="hidden"
        id="reviewModal2"
        data-bs-toggle="modal"
        data-bs-target="#reviewModalOpen2"
      />

      <section
        className="pb-0 user-registration-flow-wrapper py-0"
        id="bigPopUp"
      >
        <div className="container-fluid left-right-space extra-space-container">
          <div className="row mt-5" id="modalPopUp">
            <div
              className="modal fade user__pop__wrapper p-0 "
              id="specialistDetails"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-fullscreen transform-none transition-none modal-dialog-centered">
                <div className="modal-content py-5 position-relative specialist-pop-up subscribe-popup">
                  <div className="container-fluid container-fluid-custom">
                    <div className="row pb-2 mobile-flex-wrap">
                      <div className="col max-width-350">
                        <div className="specialist-image specialist-image-box">
                          <img
                            src={specialistData?.profile}
                            alt=""
                            className="img-fluid rounded"
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="subscription-group subscription-group-new">
                          <ul className="ps-0">
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    Name <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase font-size-24">
                                    {specialistData?.name}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    Gender <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    {specialistData?.gender}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    PROFESSIONAL <span></span> TITLE <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    {specialistData && specialistData?.DoctorExperiences && specialistData?.DoctorExperiences.length ? specialistData?.DoctorExperiences[0].title : null}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    Area of Expertise <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase font-size-20">
                                    {showExpData("areaofexpertise")}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    BIO <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    Bio info
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    Experience <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="text-white mb-0 text-uppercase explore-heading-one mb-0 text-uppercase">
                                    {specialistData.length !== 0 &&
                                      specialistData?.DoctorExperiences[0]
                                        .yearofexperience}{" "}
                                    YEARS EXPERIENCE
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 mb-0-custom text-uppercase">
                                    Language <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="explore-heading-one text-white mb-0 mb-0-custom text-uppercase">
                                    {showExpData("language")}
                                  </p>
                                </div>
                              </div>
                            </li>
                            
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                        <div className="subscription-group">
                          <ul className="ps-0">
                            <li className="star-group star-group-outer">
                              <div className="ratting-outer-box">
                                <span className="explore-heading-one text-white d-inline pe-2 vertical-center">
                                  3.5
                                </span>
                                <Rating
                                  size={30}
                                  // onClick={(value: any) => {
                                  //   checkFilter(elem, value);
                                  //   setIntialVal(value);
                                  // }}
                                  fillColor="white"
                                  emptyColor="black"
                                  SVGstorkeWidth="1px"
                                  SVGstrokeColor="white"
                                  allowFraction
                                  style={{
                                    marginBottom: "0px",
                                    paddingBottom: "0px",
                                  }}
                                  // initialValue={initialVal}
                                  className="stat-rating"
                                />
                                <span className="explore-heading-one text-white d-inline vertical-center">
                                  <a
                                    onClick={handleReviewModal2}
                                    className="text-uppercase text-white d-inline explore-heading-one text-decoration-underline cursor-pointer ps-2 text-decoration-none"
                                  >
                                    (18)
                                  </a>
                                </span>
                              </div>
                              <div className="review-outer">
                                <a
                                  onClick={handleReviewModal}
                                  className="text-uppercase text-white d-inline explore-heading-one text-decoration-underline cursor-pointer text-decoration-none"
                                >
                                  Write reviews
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                    {/* <div className="row pb-4">
                        <div className="col max-width-250">
                          <div className="specialist-image specialist-image-box">
                            <img
                              src={specialistData?.profile}
                              alt=""
                              className="img-fluid rounded"
                            />
                          </div>
                        </div>
                        <div className="col">
                              <div className="subscription-group">
                                    
                            
                                <h3 className="explore-heading-one text-white mb-0 text-uppercase">
                                  {specialistData?.name}
                                </h3>
                                <p className="mb-0 specialist-gender text-uppercase">
                                  {specialistData?.gender}
                                </p>
                                <p className="mb-0 specialist-expert text-uppercase">
                                  {showExpData("title")}
                                </p>
                                <p className="mb-0 specialst_degree text-uppercase">
                                  {showExpData("areaofexpertise")}
                                </p>

                                <p className="specialist-year-of-experience mb-0 text-uppercase">
                                  {specialistData.length !== 0 &&
                                    specialistData?.DoctorExperiences[0]
                                      .yearofexperience}{" "}
                                  YEARS EXPERIENCE
                                </p>
                                <p className="mb-0 specialist-langauge pb-2 text-uppercase">
                                  {showExpData("language")}
                                </p>

                                </div>
                        </div>
                      </div> */}
                    <hr className="text-white mt-0 custom-hr"></hr>

                    <div className="row">
                      <div className="col-lg-12 please-select-text">
                        <h2 className="text-white text-uppercase mb-0 mb-0">
                          please select appointment type
                        </h2>
                        <p className="text-white p explore-heading-one text-uppercase">
                          Please select all fields before proceeding
                        </p>
                      </div>
                      <div className="col-lg-12">
                        <div className="appointment-type-wrapper appointment-type-wrapper-box">
                          <div className="row gap-5">
                            <div className="col singular-appointment-wrapper singular-appointment-wrapper-new active">
                              <div className="mb-3 form-check form-check-custom">
                                <input
                                  type="checkbox"
                                  style={{
                                    appearance: "auto",
                                    width: "16px",
                                    height: "16px",
                                    backgroundColor: true ? "#000" : "#fff", // Change background color based on checked state
                                    border: "1px solid #000",
                                  }}
                                  className="form-check-input custom-checkbox"
                                  id="exampleCheck1"
                                />
                                <label
                                  className="form-check-label mb-0"
                                  htmlFor="exampleCheck1"
                                >
                                  SINGULAR APPOINTMENT OPTIONS:
                                </label>
                                <p className="explore-heading-one text-white text-uppercase">
                                  valid for 30 days
                                </p>
                              </div>
                              <div className="px-md-4">
                                {productData.length !== 0 &&
                                productData !== null
                                  ? productData.map((elem: any) => (
                                      <div className="d-md-flex justify-content-between align-items-center appoinment_status-one py-2 px-3 mb-2">
                                        <div className="">
                                          <p className="mb-0 appointment-text-one">
                                            {parseFloat(elem.duration).toFixed(
                                              2
                                            ) === "1.00"
                                              ? 30
                                              : 1}
                                            - MINUTE VIRTUAL APPOINTMENT{" "}
                                          </p>
                                        </div>
                                        <div className="">
                                          <div className="package-amount-in-dollar-one px-3 py-1">
                                            <div className="d-flex align-items-center">
                                              <span className="pe-1">
                                                <i className="fa-solid fa-dollar-sign"></i>
                                              </span>
                                              <span className="amount-dollar_package-one">
                                                {elem.price}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  : null}

                                <div className="py-3">
                                  <p className="mb-0 font-18 please-select-mode">
                                    PLEASE SELECT MODE OF APPOINTMENT
                                  </p>
                                </div>
                                <div className="row share mode-of-calling mode-of-calling-new">
                                  <div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 border-right pb-3 pb-md-0">
                                    <div className="d-flex justify-content-lg-start justify-content-md-start justify-content-xl-between justify-content-start align-items-center mobile-card-gap">
                                      <div>
                                        <i className="fa-solid fa-video text-white"></i>
                                      </div>
                                      <div>
                                        <p className="text-uppercase text-white mb-0">
                                          VIDEO
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 border-right pb-3 pb-md-0">
                                    <div className="d-flex justify-content-lg-start justify-content-md-start justify-content-xl-between justify-content-start align-items-center mobile-card-gap">
                                      <div>
                                        <i className="fa-solid fa-headphones text-white"></i>
                                      </div>
                                      <div>
                                        <p className="text-uppercase text-white mb-0">
                                          AUDIO
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 pb-3 pb-md-0">
                                    <div className="d-flex justify-content-lg-start justify-content-md-start justify-content-xl-between justify-content-start align-items-center mobile-card-gap vertical-center">
                                      <div>
                                        <i className="fa-solid fa-message text-white"></i>
                                      </div>
                                      <div>
                                        <p className="text-uppercase text-white mb-0">
                                          CHAT
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col singular-appointment-wrapper singular-appointment-wrapper-new">
                              <div className="mb-3 form-check custom-check">
                                <input
                                  type="checkbox"
                                  style={{
                                    appearance: "auto",
                                    width: "16px",
                                    height: "16px",
                                    backgroundColor: true ? "#000" : "#fff", // Change background color based on checked state
                                    border: "1px solid #000",
                                  }}
                                  className="form-check-input custom-checkbox subscription-option-input"
                                  id="exampleCheck1"
                                />
                                <label
                                  className="form-check-label mb-0 pb-2 subscription-option-outer"
                                  htmlFor="exampleCheck1"
                                >
                                  SUBSCRIPTION OPTIONS:{" "}
                                  <span className="save-label text-white subscription-option-label">
                                    (Save 10%)
                                  </span>
                                </label>
                                <p className="mb-0 pb-2 font-18 please-select-mode text-uppercase">
                                  Please select appointment frequency
                                </p>
                                <div className="per-month-group">
                                  <div className="d-md-flex justify-content-between align-items-center appoinment_status-one py-2 px-3 per-month-group-one per-month-active">
                                    <div className="">
                                      <p className="mb-0 appointment-text-one text-uppercase">
                                        2 per month
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-md-flex justify-content-between align-items-center appoinment_status-one py-2 px-3 per-month-group-two">
                                    <div className="">
                                      <p className="mb-0 appointment-text-one text-uppercase">
                                        4 per month
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="px-md-4">
                                <p className="mb-0 pb-2 font-18 please-select-mode">
                                  PLEASE NOTE - PRICE IS CHARGED PER APPOINTMENT
                                </p>
                                {productData.length !== 0 &&
                                productData !== null
                                  ? productData.map((elem: any) => (
                                      <div className="d-md-flex justify-content-between align-items-center appoinment_status-one py-2 px-3 mb-2">
                                        <div className="">
                                          <p className="mb-0 appointment-text-one">
                                            {parseFloat(elem.duration).toFixed(
                                              2
                                            ) === "1.00"
                                              ? 30
                                              : 1}
                                            - MINUTE VIRTUAL APPOINTMENT{" "}
                                          </p>
                                        </div>
                                        <div className="">
                                          <div className="package-amount-in-dollar-one px-3 py-1">
                                            <div className="d-flex align-items-center">
                                              <span className="pe-1">
                                                <i className="fa-solid fa-dollar-sign"></i>
                                              </span>
                                              <span className="amount-dollar_package-one">
                                                {elem.price}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  : null}

                                <div className="py-3">
                                  <p className="mb-0 font-18 please-select-mode">
                                    PLEASE SELECT MODE OF APPOINTMENT
                                  </p>
                                </div>
                                <div className="row share mode-of-calling mode-of-calling-new">
                                  <div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 border-right pb-3 pb-md-0">
                                    <div className="d-flex justify-content-lg-start justify-content-md-start justify-content-xl-between justify-content-start align-items-center mobile-card-gap">
                                      <div>
                                        <i className="fa-solid fa-video text-white"></i>
                                      </div>
                                      <div>
                                        <p className="text-uppercase text-white mb-0">
                                          VIDEO
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 border-right pb-3 pb-md-0">
                                    <div className="d-flex justify-content-lg-start justify-content-md-start justify-content-xl-between justify-content-start align-items-center mobile-card-gap">
                                      <div>
                                        <i className="fa-solid fa-headphones text-white"></i>
                                      </div>
                                      <div>
                                        <p className="text-uppercase text-white mb-0">
                                          AUDIO
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 pb-3 pb-md-0">
                                    <div className="d-flex justify-content-lg-start justify-content-md-start justify-content-xl-between justify-content-start align-items-center mobile-card-gap">
                                      <div>
                                        <i className="fa-solid fa-message text-white"></i>
                                      </div>
                                      <div>
                                        <p className="text-uppercase text-white mb-0">
                                          CHAT
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 ps-3 pe-3 text-end mt-0 mt-md-5 mt-lg-5 mt-xl-5 book-slot-outer">
                        <div className="px-md-5">
                        <button
                          className="btn book-btn book-btn-new min-height-40"
                          type="button"
                          onClick={handlBookSlotModal}
                        >
                          BOOK SLOT
                        </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cancel__pop__icon" data-bs-dismiss="modal">
                    <i className="fa-solid fa-xmark text-white bg-dark"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal  p-0 gloabl-model-settings"
        id="reviewModalOpen"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabIndex={-1}
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content user_response--popup p-2 p-md-5 p-lg-5 p-xl-5 position-relative">
            <div className="modal-body text-center my-0 py-0">
              <div className="customer-reviews w-100">
                <ul className="d-flex  ps-0 justify-content-between">
                  <li className="d-flex align-items-center">
                    <h6 className="text-white text-uppercase mb-0 explore-heading-two h5">
                      Customer review
                    </h6>
                  </li>
                  <li className="star-group star-group-outer d-flex align-items-center gap-3">
                    <div className="ratting-outer-box ratting-outer-box-review">
                      <span className="explore-heading-one explore-heading-one text-white d-flex align-items-center pe-2">
                        4.6
                      </span>
                      <Rating
                        size={30}
                        fillColor="white"
                        emptyColor="black"
                        SVGstorkeWidth="1px"
                        SVGstrokeColor="white"
                        allowFraction
                        style={{
                          marginBottom: "0px",
                          paddingBottom: "0px",
                        }}
                        // initialValue={initialVal}
                        className="stat-rating"
                      />
                      <span className="explore-heading-one text-white d-inline text-uppercase d-flex align-items-center ps-2 ">
                        4.5 OUT OF 5
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <hr className="custom-white-hr w-100 mt-0" />
              <div className="w-100 d-block">
                <label className="text-white text-start explore-heading-two w-100">
                  28,172 GLOBAL RATING
                </label>
                <textarea
                  className="form-control w-100 custom-height customer-review-textarea custom-placeholder"
                  id=""
                  rows={3}
                  defaultValue={""}
                  placeholder="TYPE HERE"
                />
              </div>
              <div className="submit-btn-custom w-100 d-flex justify-content-end">
                <button
                  className="btn book-btn book-btn-new d-block mb-0 mt-4 min-height-40"
                  data-bs-target="#specialistDetails"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  type="button"
                >
                  SUBMIT
                </button>
              </div>
            </div>
            <div
              className="cancel__pop__icon"
              data-bs-target="#specialistDetails"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              <i className="fa-solid fa-xmark text-white"></i>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal  p-0 gloabl-model-settings"
        id="reviewModalOpen2"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel3"
        tabIndex={-1}
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content user_response--popup p-2 p-md-5 p-lg-5 p-xl-5 position-relative">
            <div className="modal-body text-center my-0 py-0">
              <div className="customer-reviews w-100">
                <ul className="d-flex  ps-0 justify-content-between">
                  <li className="d-flex align-items-center">
                    <h6 className="text-white text-uppercase mb-0 explore-heading-two h5">
                      Customer review
                    </h6>
                  </li>
                  <li className="star-group star-group-outer d-flex align-items-center gap-3">
                    <div className="ratting-outer-box ratting-outer-box-review">
                      <span className="explore-heading-one explore-heading-one text-white d-flex align-items-center pe-2">
                        3.5
                      </span>
                      <Rating
                        size={30}
                        // onClick={(value: any) => {
                        //   checkFilter(elem, value);
                        //   setIntialVal(value);
                        // }}
                        fillColor="white"
                        emptyColor="black"
                        SVGstorkeWidth="1px"
                        SVGstrokeColor="white"
                        allowFraction
                        style={{
                          marginBottom: "0px",
                          paddingBottom: "0px",
                        }}
                        // initialValue={initialVal}
                        className="stat-rating"
                      />
                      <span className="explore-heading-one text-white d-inline text-uppercase d-flex align-items-center ps-2">
                        (18)
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <hr className="custom-white-hr w-100 mt-0" />
              <div className="w-100 d-block">
                <label className="text-white text-start explore-heading-two w-100">
                  28,172 GLOBAL RATING
                </label>
                <div className="d-flex align-items-center mb-2 custom-progress-bar">
                  <span className="me-2 text-white d-flex star-custom">
                    5 Star
                  </span>{" "}
                  {/* Star icon */}
                  <div className="w-100">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow={70}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  </div>
                  <span className="percentage percentage-custom ps-2 text-white">
                    70%
                  </span>
                </div>

                <div className="d-flex align-items-center mb-2 custom-progress-bar">
                  <span className="me-2 text-white  d-flex star-custom">
                    5 Star
                  </span>{" "}
                  {/* Star icon */}
                  <div className="w-100">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow={70}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  </div>
                  <span className="percentage percentage-custom ps-2 text-white">
                    70%
                  </span>
                </div>

                <div className="d-flex align-items-center mb-2 custom-progress-bar">
                  <span className="me-2 text-white  d-flex star-custom">
                    5 Star
                  </span>{" "}
                  {/* Star icon */}
                  <div className="w-100">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow={70}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  </div>
                  <span className="percentage percentage-custom ps-2 text-white">
                    70%
                  </span>
                </div>

                <div className="d-flex align-items-center mb-2 custom-progress-bar">
                  <span className="me-2 text-white  d-flex star-custom">
                    5 Star
                  </span>{" "}
                  {/* Star icon */}
                  <div className="w-100">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow={70}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  </div>
                  <span className="percentage percentage-custom ps-2 text-white">
                    70%
                  </span>
                </div>

                <div className="d-flex align-items-center mb-2 custom-progress-bar">
                  <span className="me-2 text-white  d-flex star-custom">
                    5 Star
                  </span>{" "}
                  {/* Star icon */}
                  <div className="w-100">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow={70}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  </div>
                  <span className="percentage percentage-custom ps-2 text-white">
                    70%
                  </span>
                </div>

                <div className="d-flex align-items-center mb-2 custom-progress-bar">
                  <span className="me-2 text-white  d-flex star-custom">
                    5 Star
                  </span>{" "}
                  {/* Star icon */}
                  <div className="w-100">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow={70}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  </div>
                  <span className="percentage percentage-custom ps-2 text-white">
                    70%
                  </span>
                </div>
              </div>
              <div className="submit-btn-custom w-100 d-flex justify-content-end">
                <button
                  className="btn book-btn book-btn-new d-block mb-0 mt-4 min-height-40"
                  data-bs-target="#specialistDetails2"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  type="button"
                >
                  SUBMIT
                </button>
              </div>
            </div>
            <div
              className="cancel__pop__icon"
              data-bs-target="#specialistDetails"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              <i className="fa-solid fa-xmark text-white"></i>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal  p-0 gloabl-model-settings"
        id="bookSlotPopUp"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel3"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-xl modal-dialog-centered bookSlotPopUp">
          <div className="modal-content user_response--popup p-4 p-md-5 p-lg-5 p-xl-5 position-relative">
            <div className="modal-body text-center p-0">
              <label
                className="form-check-label mb-0 text-white explore-heading-two text-left w-100"
                htmlFor="exampleCheck1"
              >
                <span className="d-block h4 text-start mb-0">
                  SINGULAR APPOINTMENT OPTIONS: VALID FOR 30 DAYS
                </span>
                <span className="available-slot d-block h6 text-start">
                  AVAILABLE SLOTS FOR - DR JULIA THOMAS
                </span>
              </label>
              <div className="book-select-options w-100 text-start">
                <div className="book-select-option-inner pb-2">
                  <p className="text-uppercase text-white explore-heading-two mb-0 pt-2 h5">
                    do you want to schedule calender?
                  </p>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      defaultValue="option1"
                    />
                    <label
                      className="form-check-label text-white explore-heading-two"
                      htmlFor="inlineRadio1"
                    >
                      <span className="ps-2"> BOOK NOW</span>
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      defaultValue="option2"
                    />
                    <label
                      className="form-check-label text-white explore-heading-two"
                      htmlFor="inlineRadio2"
                    >
                      <span className="ps-2">BOOK LATER</span>
                    </label>
                  </div>
                </div>
                <div className="row border-top m-0 ">
                  <div className="col-md-4 border-end pt-4 pb-4 pe-lg-4 pe-md-4 pe-0 ps-0 react-calendar-outer">
                    <h6 className="mb-0 text-uppercase text-white explore-heading-two pb-2">
                      Please select the date before we proceed
                    </h6>
                    <Calendar selectRange={true} onChange={onChange} value={date} />
                  </div>
                  <div className="col-md-8 ps-0 ps-md-4 ps-lg-4 pe-0 pt-4 pb-4">
                    <h5 className="h6 text-uppercase text-white explore-heading-two">
                      Available time slots for the month of september
                    </h5>
                    <div className="book-overflow">
                      <div className="slot-outer text-uppercase text-white">
                        <h5 className="text-white explore-heading-two h5">
                          04/09/2024 | Wednesday
                        </h5>
                        <div className="slot-inner row g-3">
                          <div className="slot-box col-lg-4 col-md-6">
                            <div className="slot-box-inner border pt-2 pb-2 ps-3 pe-3">
                              <h5 className="mb-0  explore-heading-two pb-0">
                                AVAILAbILITY | 1 HOUR
                              </h5>
                              <p className="mb-0 explore-heading-two  pb-0">
                                FROM - 09.00 PM{" "}
                              </p>
                              <p className="mb-0 explore-heading-two  pb-0">
                                TO - 10.00 PM{" "}
                              </p>
                            </div>
                          </div>

                          <div className="slot-box col-lg-4 col-md-6">
                            <div className="slot-box-inner border pt-2 pb-2 ps-3 pe-3">
                              <h5 className="mb-0  explore-heading-two pb-0">
                                AVAILAbILITY | 1 HOUR
                              </h5>
                              <p className="mb-0 explore-heading-two  pb-0">
                                FROM - 09.00 PM{" "}
                              </p>
                              <p className="mb-0 explore-heading-two  pb-0">
                                TO - 10.00 PM{" "}
                              </p>
                            </div>
                          </div>

                          <div className="slot-box col-lg-4 col-md-6">
                            <div className="slot-box-inner border pt-2 pb-2 ps-3 pe-3">
                              <h5 className="mb-0  explore-heading-two pb-0">
                                AVAILAbILITY | 1 HOUR
                              </h5>
                              <p className="mb-0 explore-heading-two  pb-0">
                                FROM - 09.00 PM{" "}
                              </p>
                              <p className="mb-0 explore-heading-two  pb-0">
                                TO - 10.00 PM{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <hr className="custom-hr custom-hr-white text-white"></hr>
                      <div className="slot-outer text-uppercase text-white">
                        <h5 className="text-white explore-heading-two h5">
                          04/09/2024 | Wednesday
                        </h5>
                        <div className="slot-inner row g-3">
                          <div className="slot-box col-lg-4 col-md-6 slot-box-active">
                            <div className="slot-box-inner border pt-2 pb-2 ps-3 pe-3">
                              <h5 className="mb-0  explore-heading-two pb-0">
                                AVAILAbILITY | 1 HOUR
                              </h5>
                              <p className="mb-0 explore-heading-two  pb-0">
                                FROM - 09.00 PM{" "}
                              </p>
                              <p className="mb-0 explore-heading-two  pb-0">
                                TO - 10.00 PM{" "}
                              </p>
                            </div>
                          </div>

                          <div className="slot-box col-lg-4 col-md-6">
                            <div className="slot-box-inner border pt-2 pb-2 ps-3 pe-3">
                              <h5 className="mb-0  explore-heading-two pb-0">
                                AVAILAbILITY | 1 HOUR
                              </h5>
                              <p className="mb-0 explore-heading-two  pb-0">
                                FROM - 09.00 PM{" "}
                              </p>
                              <p className="mb-0 explore-heading-two  pb-0">
                                TO - 10.00 PM{" "}
                              </p>
                            </div>
                          </div>

                          <div className="slot-box col-lg-4 col-md-6">
                            <div className="slot-box-inner border pt-2 pb-2 ps-3 pe-3">
                              <h5 className="mb-0  explore-heading-two pb-0">
                                AVAILAbILITY | 1 HOUR
                              </h5>
                              <p className="mb-0 explore-heading-two  pb-0">
                                FROM - 09.00 PM{" "}
                              </p>
                              <p className="mb-0 explore-heading-two  pb-0">
                                TO - 10.00 PM{" "}
                              </p>
                            </div>
                          </div>

                          <div className="slot-box col-lg-4 col-md-6">
                            <div className="slot-box-inner border pt-2 pb-2 ps-3 pe-3">
                              <h5 className="mb-0  explore-heading-two pb-0">
                                AVAILAbILITY | 1 HOUR
                              </h5>
                              <p className="mb-0 explore-heading-two  pb-0">
                                FROM - 09.00 PM{" "}
                              </p>
                              <p className="mb-0 explore-heading-two  pb-0">
                                TO - 10.00 PM{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr className="custom-hr custom-hr-white text-white"></hr>
                      <div className="slot-outer text-uppercase text-white">
                        <h5 className="text-white explore-heading-two h5">
                          04/09/2024 | Wednesday
                        </h5>
                        <div className="slot-inner row g-3">
                          <div className="slot-box col-lg-4 col-md-6 slot-box-active">
                            <div className="slot-box-inner border pt-2 pb-2 ps-3 pe-3">
                              <h5 className="mb-0  explore-heading-two pb-0">
                                AVAILAbILITY | 1 HOUR
                              </h5>
                              <p className="mb-0 explore-heading-two  pb-0">
                                FROM - 09.00 PM{" "}
                              </p>
                              <p className="mb-0 explore-heading-two  pb-0">
                                TO - 10.00 PM{" "}
                              </p>
                            </div>
                          </div>

                          <div className="slot-box col-lg-4 col-md-6">
                            <div className="slot-box-inner border pt-2 pb-2 ps-3 pe-3">
                              <h5 className="mb-0  explore-heading-two pb-0">
                                AVAILAbILITY | 1 HOUR
                              </h5>
                              <p className="mb-0 explore-heading-two  pb-0">
                                FROM - 09.00 PM{" "}
                              </p>
                              <p className="mb-0 explore-heading-two  pb-0">
                                TO - 10.00 PM{" "}
                              </p>
                            </div>
                          </div>

                          <div className="slot-box col-lg-4 col-md-6">
                            <div className="slot-box-inner border pt-2 pb-2 ps-3 pe-3">
                              <h5 className="mb-0  explore-heading-two pb-0">
                                AVAILAbILITY | 1 HOUR
                              </h5>
                              <p className="mb-0 explore-heading-two  pb-0">
                                FROM - 09.00 PM{" "}
                              </p>
                              <p className="mb-0 explore-heading-two  pb-0">
                                TO - 10.00 PM{" "}
                              </p>
                            </div>
                          </div>

                          <div className="slot-box col-lg-4 col-md-6">
                            <div className="slot-box-inner border pt-2 pb-2 ps-3 pe-3">
                              <h5 className="mb-0  explore-heading-two pb-0">
                                AVAILAbILITY | 1 HOUR
                              </h5>
                              <p className="mb-0 explore-heading-two  pb-0">
                                FROM - 09.00 PM{" "}
                              </p>
                              <p className="mb-0 explore-heading-two  pb-0">
                                TO - 10.00 PM{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cancel__pop__icon" data-bs-dismiss="modal">
              <i className="fa-solid fa-xmark text-white"></i>
            </div>
            {/* <button
              className="btn btn-primary btn-sm"
              data-bs-target="#specialistDetails"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              Back to first
            </button> */}
            <div className="submit-btn-custom w-100 d-flex justify-content-end"><button className="btn book-btn book-btn-new d-block mb-0 mt-0 min-height-40" data-bs-target="#specialistDetails" data-bs-toggle="modal" data-bs-dismiss="modal" type="button">SUBMIT</button></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookSpecialist;
